/**
 * @flow
 */

import React from 'react';
import { Layout } from 'antd';
import moment from 'moment';

import site from '../config/site';

const year = moment().format('YYYY');

export const Container = (props: { children: any }) => {
  const {
    children
  } = props

  return <Layout>
    {children}
  </Layout>;
}

export const Content = (props: { children: any }) => {
  const {
    children
  } = props;

  return <Layout.Content>
    <div style={{ margin: 16, padding: 16, background: '#fff', minHeight: 640 }}>
      {children}
    </div>
  </Layout.Content>;
}

export const Footer = () => <Layout.Footer style={{ textAlign: 'center' }}>
  AusTV © {year}
</Layout.Footer>;

export const Logo = () => <div style={{ paddingTop: 8, paddingLeft: 8, paddingBottom: 16 }}>
  <img src={`${site.public}/images/logo-small.png`} alt='AusTV Logo' style={{ width: 64, borderRadius: 3 }} />
</div>;
