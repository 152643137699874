/**
 * @flow
 */

import React from 'react';
import { notification, Icon, Button } from 'antd';

function onError(ex: any) {
  console.log(ex);

  const key = `open${Date.now()}`;
  const btn = <Button type='primary' onClick={() => notification.close(key)}>
    Ok
  </Button>;

  const args = {
    btn,
    key,
    message: 'Error',
    description: `An error has occurred: '${ex.message}'.`,
    duration: 0,
    icon: <Icon type='warning' style={{ color: 'red' }} />,
  };

  notification.open(args);
};

export {
  onError
};
