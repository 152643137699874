/**
 * @flow
 */

import type { Action } from '../actions/types';
import type { User, UserQuery, PageModel } from '../models';

const initial: PageModel<User, UserQuery> = {};

function reducer(state: PageModel<User, UserQuery> = initial, action: Action): PageModel<User, UserQuery> {
  switch (action.type) {
    case 'USERS.GET_INSTALLATIONS':
      return {
        ...state,
        details: {
          ...state.details,
          installations: {
            ...action.data,
            timestamp: new Date(),
          },
        }
      };
    case 'USERS.GET_PROGRAMS':
      return {
        ...state,
        details: {
          ...state.details,
          programs: {
            ...action.data,
            timestamp: new Date(),
          },
        }
      };
    case 'USERS.GET_NOTIFICATIONS':
      return {
        ...state,
        details: {
          ...state.details,
          notifications: {
            ...action.data,
            timestamp: new Date(),
          },
        }
      };
    case 'USERS.GET_NOTIFICATION_SCHEDULES':
      return {
        ...state,
        details: {
          ...state.details,
          notificationSchedules: {
            ...action.data,
            timestamp: new Date(),
          },
        }
      };
    case 'USERS.GET_SEARCHES':
      return {
        ...state,
        details: {
          ...state.details,
          searches: {
            ...action.data,
            timestamp: new Date(),
          },
        }
      };
    case 'USERS.GET_PROGRAM_VIEWS':
      return {
        ...state,
        details: {
          ...state.details,
          programViews: {
            ...action.data,
            timestamp: new Date(),
          },
        }
      };
    case 'USERS.GET_DETAILS':
      return {
        ...state,
        details: {
          ...action.data,
          timestamp: new Date(),
        }
      };
    case 'USERS.GET_LIST':
      return {
        ...state,
        list: {
          ...action.data,
          timestamp: new Date(),
        }
      };
    case 'USERS.CLEAR':
    case 'AUTH.LOGGED_OUT':
      return initial;
    default:
      return state;
  }
}

export {
  reducer,
};