/**
 * @flow
 */

import { History } from 'history';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import {
  applyMiddleware,
  combineReducers,
  compose,
  createStore,
  ReducersMapObject,
} from 'redux';

import thunk from 'redux-thunk';
import type { AppState } from './models';
import Reducers from './reducers';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router']
}

const build = (reducers: ReducersMapObject, history: History) => {
  return combineReducers({
    router: connectRouter(history),
    ...reducers,
  });
}

export default function configureStore(history: History, state?: AppState) {
  const proxy = typeof window === 'undefined' ? null : window;

  // if devTools is installed, connect to it
  const tools = proxy && proxy.__REDUX_DEVTOOLS_EXTENSION__;
  const createStoreWithMiddleware = compose(
    applyMiddleware(thunk, routerMiddleware(history)),
    tools ? tools() : next => next
  )(createStore);

  // Combine all reducers and instantiate the app-wide store instance
  const reducers = build(Reducers, history);
  const persistedReducer = persistReducer(persistConfig, reducers)
  const store = createStoreWithMiddleware(persistedReducer, state);
  const persistor = persistStore(store)

  // enable Webpack hot module replacement for reducers
  // $FlowFixMe
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      const next = require('./reducers');
      store.replaceReducer(build(next));
    });
  }

  return { store, persistor };
}
