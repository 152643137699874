/**
 * @flow
 */

export default {
  auth: require('./auth').reducer,
  users: require('./users').reducer,
  jobs: require('./jobs').reducer,
  bugReports: require('./bugReports').reducer,
  programs: require('./programs').reducer,
};
