/**
 * @flow
 */

import type { ThunkAction } from './types';
import client from '../../api/client';
import type { QueryOptions, BugReportQuery } from '../../store/models';

function getBugReportDetails(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.bugReports.details(id, options).then(response => {
      dispatch({
        type: 'BUGREPORTS.GET_DETAILS',
        data: response,
      });

      return true;
    });
  };
}

function getBugReportList(query?: BugReportQuery, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.bugReports.find(query, options).then(response => {
      dispatch({
        type: 'BUGREPORTS.GET_LIST',
        data: {
          ...response,
          query,
          options,
        },
      });

      return true;
    });
  };
}

function clearBugReports(): ThunkAction {
  return dispatch => {
    dispatch({
      type: 'BUGREPORTS.CLEAR',
    });
  };
}

export {
  getBugReportDetails,
  getBugReportList,
  clearBugReports,
};
