/**
 * @flow
 */

import React, { Component } from 'react';
import { Layout, Row, Col, Button } from 'antd';
import { connect, DispatchProp } from 'react-redux';
import type { Location } from 'history';
import type { AppState, AppUser } from '../store/models';

import items from '../config/menu';
import { logOut } from '../store/actions';

const { Header } = Layout;

type Props = {
  routing: Location,
  auth: AppUser
} & DispatchProp;

class AppHeader extends Component<Props> {
  onLogOut = async () => {
    try {
      const {
        dispatch
      } = this.props;

      await dispatch(logOut());
    } catch (ex) {
      console.log(ex);
    }
  }

  render() {
    const {
      routing: {
        location: {
          pathname = '/',
        } = {}
      } = {},
      auth: {
        email
      },
    } = this.props;

    const menu = items[pathname];
    return <Header style={{ background: '#fff', paddingLeft: 14, paddingRight: 16 }}>
      <Row>
        <Col span={12}><h1>{(menu && menu.name) || 'Hello'}</h1></Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <a href={`mailto:${email || ''}`} style={{ paddingRight: 8 }}>{email}</a>
          <Button type='' icon='logout' onClick={this.onLogOut}>Log Out</Button>
        </Col>
      </Row>
    </Header>;
  }
}

export default connect((s: AppState) => ({
  routing: s && s.router,
  auth: s && s.auth,
}))(AppHeader);