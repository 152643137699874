/**
 * @flow
 */

import type { ThunkAction } from './types';
import client from '../../api/client';

function logIn(email: string, password: string): ThunkAction {
  return dispatch => {
    return client.logIn(email, password).then(response => {
      dispatch({
        type: 'AUTH.LOGGED_IN',
        data: {
          name: response.get('name'),
          email,
          isLoggedIn: true,
        },
      });

      return true;
    });
  };
}

function logOut(): ThunkAction {
  return dispatch => {
    return client.logOut().then(() => {
      dispatch({
        type: 'AUTH.LOGGED_OUT',
      });
    });
  };
}

export {
  logIn,
  logOut,
};
