/**
 * @flow
 */

import type { ThunkAction } from './types';
import client from '../../api/client';
import type { QueryOptions, ProgramQuery } from '../../store/models';

function getProgramDetails(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.programs.details(id, options).then(response => {
      dispatch({
        type: 'PROGRAMS.GET_DETAILS',
        data: response,
      });

      return true;
    });
  };
}

function getProgramList(query?: ProgramQuery, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.programs.find(query, options).then(response => {
      dispatch({
        type: 'PROGRAMS.GET_LIST',
        data: {
          ...response,
          query,
          options,
        },
      });

      return true;
    });
  };
}

function clearPrograms(): ThunkAction {
  return dispatch => {
    dispatch({
      type: 'PROGRAMS.CLEAR',
    });
  };
}

export {
  getProgramDetails,
  getProgramList,
  clearPrograms,
};
