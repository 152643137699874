/**
 * @flow
 */

import Parse from 'parse';
import config from '../config/parse';
import { map } from 'underscore';

import type {
  QueryOptions,
  User,
  UserQuery,
  JobStats,
  JobStatsQuery,
  Installation,
  UserProgram,
  UserNotification,
  UserNotificationSchedule,
  UserSearch,
  UserProgramView,
  BugReport,
  BugReportQuery,
  Program,
  ProgramQuery
} from '../store/models';

const Domain = {
  JobStats: Parse.Object.extend('JobStats'),
  UserProgram: Parse.Object.extend('UserProgram'),
  UserNotification: Parse.Object.extend('UserNotification'),
  UserNotificationSchedule: Parse.Object.extend('UserNotificationSchedule'),
  UserSearch: Parse.Object.extend('UserSearch'),
  ProgramView: Parse.Object.extend('ProgramView'),
  BugReport: Parse.Object.extend('BugReport'),
  Region: Parse.Object.extend('Region'),
  Program: Parse.Object.extend('Program'),
};

const withMasterKey = { useMasterKey: true, sessionToken: '' };
type ResultList<T> = {
  count: number,
  results: T[],
};

async function executeQueryAsMaster<T>(
  query: Parse.Query,
  action: string
): Promise<ResultList<T>> {
  await tryConfigureParse();

  const response = await query[action](withMasterKey);
  const count = await query.count(withMasterKey);

  const results = map(response || [], x => x.toJSON());
  return { count, results };
}

async function executeGetAsMaster<T>(
  query: Parse.Query,
  action: string
): Promise<?T> {
  await tryConfigureParse();

  const response = await query[action](withMasterKey);
  if (response) {
    return response.toJSON();
  }

  return undefined;
}

function applyOptionsToQuery(query: Parse.Query, options?: QueryOptions): Parse.Query {
  if (!options) {
    return query;
  }

  const {
    limit,
    skip,
    select,
    ascending,
    descending,
    include,
  } = options;

  if (limit) {
    query = query.limit(limit);
  }
  if (skip) {
    query = query.skip(skip);
  }
  if (select) {
    query = query.select(select);
  }
  if (ascending) {
    query = query.ascending(ascending);
  }
  if (descending) {
    query = query.descending(descending);
  }
  if (include) {
    query = query.include(include);
  }

  return query;
}

async function tryConfigureParse(): Promise<void> {
  try {
    if (!global.isParseConfigured) {
      const config = await Parse.Cloud.run('getParseConfig');
      if (config) {
        const {
          id,
          key,
        } = config;

        if (id && key) {
          Parse._initialize(id, null, key);
          global.isParseConfigured = true;
        }
      }
    }
  } catch(ex) {
    console.log(ex);
  }
}

export default {
  init: () => {
    Parse._initialize(config.id, null, null);
    Parse.serverURL = config.url;
  },

  logIn: async (email: string, password: string): Promise<Parse.User> => {
    email = email && email.toLowerCase();
    const user = await Parse.User.logIn(email, password);

    await tryConfigureParse();
    return user;
  },

  logOut: async () => {
    global.isParseConfigured = undefined;
    Parse._initialize(config.id, null, null);

    await Parse.User.logOut();
  },

  // USERS
  users: {
    installations: async (objectId: string, options?: QueryOptions): Promise<?ResultList<Installation>> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Parse.Installation);
      query = query.equalTo('user', Parse.User.createWithoutData(objectId));

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
    programs: async (objectId: string, options?: QueryOptions): Promise<?ResultList<UserProgram>> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Domain.UserProgram);
      query = query.equalTo('user', Parse.User.createWithoutData(objectId));

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
    notifications: async (objectId: string, options?: QueryOptions): Promise<?ResultList<UserNotification>> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Domain.UserNotification);
      query = query.equalTo('user', Parse.User.createWithoutData(objectId));

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
    notificationSchedules: async (objectId: string, options?: QueryOptions): Promise<?ResultList<UserNotificationSchedule>> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Domain.UserNotificationSchedule);
      query = query.equalTo('user', Parse.User.createWithoutData(objectId));

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
    searches: async (objectId: string, options?: QueryOptions): Promise<?ResultList<UserSearch>> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Domain.UserSearch);
      query = query.equalTo('user', Parse.User.createWithoutData(objectId));

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
    programViews: async (objectId: string, options?: QueryOptions): Promise<?ResultList<UserProgramView>> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Domain.ProgramView);
      query = query.equalTo('user', Parse.User.createWithoutData(objectId));

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
    details: async (objectId: string, options?: QueryOptions): Promise<?User> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Parse.User);
      query = query.equalTo('objectId', objectId);

      query = applyOptionsToQuery(query, options);
      return await executeGetAsMaster(query, 'first');
    },
    find: async (search?: UserQuery, options?: QueryOptions): Promise<ResultList<User>> => {
      let query = new Parse.Query(Parse.User);

      if (search && search.objectId) {
        query = query.equalTo('objectId', search.objectId);
      }
      if (search && search.name) {
        query = query.matches('name', search.name.toLowerCase(), 'i');
      }
      if (search && search.region) {
        query = query.matchesQuery('region', new Parse.Query(Domain.Region)
          .matches('name', search.region.toLowerCase(), 'i')
        );
      }
      if (search && search.purchase === 'true') {
        query = query.matchesKeyInQuery('objectId', 'user.objectId', new Parse.Query(Parse.Installation)
          .exists('user')
          .notEqualTo('user', null)
          .exists('purchase')
        );
      }
      if (search && search.purchase === 'false') {
        query = query.matchesKeyInQuery('objectId', 'user.objectId', new Parse.Query(Parse.Installation)
          .exists('user')
          .notEqualTo('user', null)
          .doesNotExist('purchase')
        );
      }
      if (search && search.deviceType) {
        query = query.matchesKeyInQuery('objectId', 'user.objectId', new Parse.Query(Parse.Installation)
          .exists('user')
          .notEqualTo('user', null)
          .equalTo('deviceType', search.deviceType)
        );
      }
      if (search && search.email) {
        query = query.matches('email', search.email.toLowerCase(), 'i');
      }

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
  },

  // JOBS
  jobs: {
    details: async (objectId: string, options?: QueryOptions): Promise<?JobStats> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Domain.JobStats);
      query = query.equalTo('objectId', objectId);

      query = applyOptionsToQuery(query, options);
      return await executeGetAsMaster(query, 'first');
    },
    find: async (search?: JobStatsQuery, options?: QueryOptions): Promise<ResultList<JobStats>> => {
      let query = new Parse.Query(Domain.JobStats);

      if (search && search.objectId) {
        query = query.equalTo('objectId', search.objectId);
      }
      if (search && search.name) {
        query = query.matches('name', search.name.toLowerCase(), 'i');
      }

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
  },

  // BUGREPORTS
  bugReports: {
    details: async (objectId: string, options?: QueryOptions): Promise<?BugReport> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Domain.BugReport);
      query = query.equalTo('objectId', objectId);

      query = applyOptionsToQuery(query, options);
      return await executeGetAsMaster(query, 'first');
    },
    find: async (search?: BugReportQuery, options?: QueryOptions): Promise<ResultList<BugReport>> => {
      let query = new Parse.Query(Domain.BugReport);

      if (search && search.objectId) {
        query = query.equalTo('objectId', search.objectId);
      }
      if (search && search.user) {
        query = query.matches('user.name', search.user.toLowerCase(), 'i');
      }

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
  },

  // PROGRAMS
  programs: {
    details: async (objectId: string, options?: QueryOptions): Promise<?Program> => {
      if (!objectId) {
        return undefined;
      }

      let query = new Parse.Query(Domain.Program);
      query = query.equalTo('objectId', objectId);

      query = applyOptionsToQuery(query, options);
      return await executeGetAsMaster(query, 'first');
    },
    find: async (search?: ProgramQuery, options?: QueryOptions): Promise<ResultList<Program>> => {
      let query = new Parse.Query(Domain.Program);

      if (search && search.objectId) {
        query = query.equalTo('objectId', search.objectId);
      }
      if (search && search.title) {
        query = query.matches('title', search.title.toLowerCase(), 'i');
      }

      query = applyOptionsToQuery(query, options);
      return await executeQueryAsMaster(query, 'find');
    },
  },
};
