/**
 * @flow
 */

import type { ThunkAction } from './types';
import client from '../../api/client';
import type { QueryOptions, UserQuery } from '../../store/models';

function getUserInstallations(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.users.installations(id, options).then(response => {
      dispatch({
        type: 'USERS.GET_INSTALLATIONS',
        data: {
          ...response
        },
      });

      return true;
    });
  };
}

function getUserPrograms(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.users.programs(id, options).then(response => {
      dispatch({
        type: 'USERS.GET_PROGRAMS',
        data: {
          ...response
        },
      });

      return true;
    });
  };
}

function getUserNotifications(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.users.notifications(id, options).then(response => {
      dispatch({
        type: 'USERS.GET_NOTIFICATIONS',
        data: {
          ...response
        },
      });

      return true;
    });
  };
}

function getUserNotificationSchedules(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.users.notificationSchedules(id, options).then(response => {
      dispatch({
        type: 'USERS.GET_NOTIFICATION_SCHEDULES',
        data: {
          ...response
        },
      });

      return true;
    });
  };
}

function getUserSearches(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.users.searches(id, options).then(response => {
      dispatch({
        type: 'USERS.GET_SEARCHES',
        data: {
          ...response
        },
      });

      return true;
    });
  };
}

function getUserProgramViews(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.users.programViews(id, options).then(response => {
      dispatch({
        type: 'USERS.GET_PROGRAM_VIEWS',
        data: {
          ...response
        },
      });

      return true;
    });
  };
}

function getUserDetails(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.users.details(id, options).then(response => {
      dispatch({
        type: 'USERS.GET_DETAILS',
        data: response,
      });

      return true;
    });
  };
}

function getUserList(query?: UserQuery, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.users.find(query, options).then(response => {
      dispatch({
        type: 'USERS.GET_LIST',
        data: {
          ...response,
          query,
          options,
        },
      });

      return true;
    });
  };
}

function clearUser(): ThunkAction {
  return dispatch => {
    dispatch({
      type: 'USERS.CLEAR',
    });
  };
}

export {
  getUserInstallations,
  getUserPrograms,
  getUserNotifications,
  getUserNotificationSchedules,
  getUserSearches,
  getUserProgramViews,
  getUserDetails,
  getUserList,
  clearUser,
};
