/**
 * @flow
 */

import React, { Component } from 'react';
import Loadable from 'react-loadable';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';

import { Container, Menu, Header, Footer, Content } from './layout';
import { Progress } from './components'

import type { AppState, AppUser } from './store/models';

const Loading = () => <Progress spinning={true} />;

const Home = Loadable({
  loader: () => import('./containers/home'),
  loading: Loading,
});
const UserList = Loadable({
  loader: () => import('./containers/users/list'),
  loading: Loading,
});
const UserDetails = Loadable({
  loader: () => import('./containers/users/details'),
  loading: Loading,
});
const Programs = Loadable({
  loader: () => import('./containers/programs/list'),
  loading: Loading,
});
const ProgramDetails = Loadable({
  loader: () => import('./containers/programs/details'),
  loading: Loading,
});
const Jobs = Loadable({
  loader: () => import('./containers/jobs/list'),
  loading: Loading,
});
const BugReports = Loadable({
  loader: () => import('./containers/bugReports/list'),
  loading: Loading,
});
const Login = Loadable({
  loader: () => import('./containers/login'),
  loading: Loading,
});
const Route404 = Loadable({
  loader: () => import('./containers/route404'),
  loading: Loading,
});

class App extends Component<{ auth: AppUser }> {
  render() {
    const {
      auth: {
        isLoggedIn
      }
    } = this.props;

    if (isLoggedIn) {
      return <Container>
        <Menu />
        <Container>
          <Header />
          <Content>
            <Switch>
              <Redirect from={'/index.html'} to={'/'} />
              <Route exact path={'/'} component={Home} />
              <Route exact path={'/users'} component={UserList} />
              <Route path={'/users/:id'} component={UserDetails} />
              <Route exact path={'/programs'} component={Programs} />
              <Route path={'/programs/:id'} component={ProgramDetails} />
              <Route path={'/bug-reports'} component={BugReports} />
              <Route path={'/jobs'} component={Jobs} />
              <Route component={Route404} />
            </Switch>
          </Content>
          <Footer />
        </Container>
      </Container>;
    }

    return <Switch>
      <Route path='/*' component={Login} />
    </Switch>;
  }
}

export default withRouter(connect((s: AppState) => ({
  auth: s && s.auth
}))(App));