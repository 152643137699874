/**
 * @flow
 */

import type { Action } from '../actions/types';
import type { AppUser } from '../models';

const initial: AppUser = {
  isLoggedIn: false,
};

function reducer(state: AppUser = initial, action: Action): AppUser {
  switch (action.type) {
    case 'AUTH.LOGGED_IN':
      return {
        ...action.data,
        timestamp: new Date(),
      };
    case 'AUTH.LOGGED_OUT':
      return initial;
    default:
      return state;
  }
}

export {
  reducer,
};
