/**
 * @flow
 */

import type { ThunkAction } from './types';
import client from '../../api/client';
import type { QueryOptions, JobStatsQuery } from '../../store/models';

function getJobDetails(id: string, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.jobs.details(id, options).then(response => {
      dispatch({
        type: 'JOBS.GET_DETAILS',
        data: response,
      });

      return true;
    });
  };
}

function getJobList(query?: JobStatsQuery, options?: QueryOptions): ThunkAction {
  return dispatch => {
    return client.jobs.find(query, options).then(response => {
      dispatch({
        type: 'JOBS.GET_LIST',
        data: {
          ...response,
          query,
          options,
        },
      });

      return true;
    });
  };
}

function clearJobs(): ThunkAction {
  return dispatch => {
    dispatch({
      type: 'JOBS.CLEAR',
    });
  };
}

export {
  getJobDetails,
  getJobList,
  clearJobs,
};
