/**
 * @flow
 */

import React from 'react';
import ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'

import client from './api/client';
import configureStore from './store/configure';
import site from './config/site';

import './styles/index.css';
import './styles/app.css';

import registerServiceWorker from './worker';

let App = require('./app').default;

client.init();

const history = createBrowserHistory({basename: site.public});
const store = configureStore(history).store;

const render = () => {
  let root = document.getElementById('root');
  if (root) {
    let app = <AppContainer>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </AppContainer>;

    ReactDOM.render(app, root);
  }
}

render();

// allow hot module replacement
// $FlowFixMe
if (module.hot) {
  module.hot.accept('./app', () => {
    App = require('./app').default;
    render();
  });
}

registerServiceWorker();
