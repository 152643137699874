/**
 * @flow
 */

const menu = {};

menu['/'] = {
  icon: 'home',
  name: 'Home',
};
menu['/users'] = {
  icon: 'team',
  name: 'Users',
};
menu['/programs'] = {
  icon: 'switcher',
  name: 'Programs',
};
menu['/bug-reports'] = {
  icon: 'frown-o',
  name: 'Bug Reports',
};
menu['/jobs'] = {
  icon: 'appstore',
  name: 'Jobs',
};

export default menu;
