/**
 * @flow
 */

import React from 'react';
import { Spin, Icon, Button } from 'antd';

import config from '../config/parse';
const indicator = <Icon type='loading' style={{ fontSize: 24 }} spin />;

export const Progress = (props: Spin.SpinProps) => (
  <Spin {...props} indicator={indicator} />
);

export const ReloadButton = (props: any) => (
  <Button {...props}><Icon type='reload'/></Button>
);

export const ParseLink = (props: {type: string; pipe?: 'left' | 'right'; title?: string; object: any}) => {
  const {
    type,
    pipe,
    title,
    object: {
      objectId
    } = {}
  } = props || {};

  if (!type || !objectId || !config.ds) {
    return null;
  }

  const url = `${config.ds}/browser/${type}?filters=[{"field":"objectId","constraint":"eq","compareTo":"${objectId}"}]`
  return <span>
    {pipe === 'left' && ' | '}<a href={url} target='_blank' rel='noopener noreferrer'>{
      title || 'DS'
    }</a>{pipe === 'right' && ' | '}
  </span>
};

export const TableTitle = (props: {isActivity: bool, onReload: any}) => <div style={{textAlign: 'right'}}>
  <Progress style={{marginRight: '16px'}} spinning={props.isActivity} />
  <ReloadButton onClick={props.onReload} />
</div>;