/**
 * @flow
 */

import type { Action } from '../actions/types';
import type { Program, ProgramQuery, PageModel } from '../models';

const initial: PageModel<Program, ProgramQuery> = {};

function reducer(state: PageModel<Program, ProgramQuery> = initial, action: Action): PageModel<Program, ProgramQuery> {
  switch (action.type) {
    case 'PROGRAMS.GET_DETAILS':
      return {
        ...state,
        details: {
          ...action.data,
          timestamp: new Date(),
        }
      };
    case 'PROGRAMS.GET_LIST':
      return {
        ...state,
        list: {
          ...action.data,
          timestamp: new Date(),
        }
      };
    case 'PROGRAMS.CLEAR':
    case 'AUTH.LOGGED_OUT':
      return initial;
    default:
      return state;
  }
}

export {
  reducer,
};
