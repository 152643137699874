/**
 * @flow
 */

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Icon, Layout } from 'antd';
import { map } from 'underscore';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import type { Location, History } from 'history';
import type { AppState } from '../store/models';

import { Logo } from './components';
import items from '../config/menu';

const { Sider } = Layout;
const { Item, ClickParam } = Menu;

type Props = {
  location: Location;
  history: History;
  routing: Location;
};

type State = {
  collapsed: bool;
};

class AppMenu extends Component<Props, State> {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed: bool) => {
    this.setState({ collapsed });
  }

  onClick = (e: ClickParam) => {
    this.props.history.push(e.key)
  }

  render() {
    const {
      collapsed,
    } = this.state;

    // console.log(this.props, this.state);
    const {
      routing: {
        location: {
          pathname = '/',
        } = {}
      } = {}
    } = this.props;

    const options = map(items, (i, k) => {
      return <Item key={k}>
        <Icon type={items[k].icon} />
        <span>{items[k].name}</span>
      </Item>;
    });

    let selected = '';
    if (pathname !== '/') {
      const parts = pathname.split('/') || [];
      selected = (parts.length > 1 && parts[1]) || pathname;
    }

    return <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={this.onCollapse}>
      <Link to={'/'} style={{ display: 'inline' }}><Logo /></Link>
      <Menu
        onClick={this.onClick}
        theme='dark'
        selectedKeys={[`/${selected}`]}
        defaultSelectedKeys={['/']}
        mode='inline'>
        {options}
      </Menu>
    </Sider>;
  }
}

export default withRouter(connect((s: AppState) => ({
  routing: s && s.router
}))(AppMenu));
